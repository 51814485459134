import './CandlePage.css'
import {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'

// Actions
import { getCandleDetails } from '../redux/actions/candleActions'
import { addToCart } from '../redux/actions/cartActions'
import { getScents } from '../redux/actions/scentActions'

import { ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CandlePage = () => {

  const params = useParams()
  const navigate = useNavigate()
 
  const [qty, setQty] = useState(1);
  const [scentChosen, setScentChosen] = useState('');
  const dispatch = useDispatch();

  const candleDetails = useSelector((state) => state.getCandleDetails);
  const {candle, loading, error} = candleDetails;

  const allScents = useSelector( (state) => state.getScents);
  const { scents } = allScents;
 
  useEffect(() => {
    if(candle && params.id !== candle._id) {
      dispatch(getCandleDetails(params.id));
    }
  }, [dispatch, candle, params])

  useEffect(() => {
    dispatch(getScents())
  }, [dispatch])

  const addToCartHandler = () => {
    if(candle.countInStock!==0 && scentChosen!==''){
      dispatch(addToCart(candle._id, qty, scentChosen));
      navigate("/cart")
    } else if(candle.countInStock === 0){
      toast.warn('Item Out Of Stock- Contact Me to Restock')
    }else{
      toast.warn('Select a Scent')
    }
  }

  return (
    <div className='candlepage'>
      {loading ? <h2>Loading...</h2> : error ? <h2>{error}</h2> : (
        <>
      <div className="candlepage_left">
        <div className="left_image">
          <img src={candle.imageUrl} alt={candle.name}></img>
        </div>
      </div>

      <div className="candlepage_right">
        <div className="right_info">
          <h3>{candle.name}</h3>
          <p> Price: <span>${candle.price}</span></p>
          <p> Size: <span>{candle.size}</span></p>
          <p> Status:
            <span>
              {candle.countInStock > 0 ? "In Stock" : "Out of Stock"}
            </span>
          </p>
          <p> 
            Scent:
            <select value = {scentChosen} onChange = {(e)=> setScentChosen(e.target.value)}>
              <option value = ''>Choose a Scent</option>
              {scents.map((x)=>(
                <option key={x.scent} value = {x.scent}>{x.scent}</option>
              ))}
            </select>
          </p>
          <p> 
            Qty:
            <select value = {qty} onChange = {(e)=> setQty(e.target.value)}>
              {[...Array(candle.countInStock).keys()].map((x)=>(
                <option key={x+1} value = {x+1}>{x+1}</option>
              ))}
            </select>
          </p>
          <p>
            <button disable = {String(candle.countInStock === 0)} type = 'button' onClick={addToCartHandler}>Add to Cart</button>
          </p>
        </div>
      </div>
        </>
      )}
      <ToastContainer autoClose = {3000}/>
    </div>
  )
}

export default CandlePage
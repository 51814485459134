import "../Adminpage.css";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddPromo = () => {
  const [coupons, setCoupons] = useState([]);
  const [promos, setPromos] = useState([]);
  const [inputs, setInputs] = useState({});
  const [promoChosen, setPromoChosen] = useState("");

  // Get existing promo codes and coupons from database
  useEffect(async () => {
    const { data } = await axios.get("/api/stripe/v1/promotion_codes");
    setPromos(data.data);
  }, []);

  useEffect(async () => {
    const { data } = await axios.get("/api/stripe/v1/coupons");
    setCoupons(data.data);
  }, []);

  // handle changes in input
  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  // Send form data to server to create new promocode
  const uploadPromo = async (e) => {
    e.preventDefault();
    let min = Number(e.target.minAmount.value);
    const response = await axios.post("/api/stripe/v1/promotion_codes", {
      coupon: e.target.coupon.value,
      code: e.target.promo.value,
      minAmount: min > 1 ? min : null,
      minAmountCurrency: "USD",
    });
    console.log(response);
    if (response.data.raw) {
      toast.error(response.data.raw.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      if (response.data.url) window.location = response.data.url;
      else window.location.reload();
    }
    e.target.reset();
  };

  // Send form data to server to create new coupon
  const uploadCoupon = async (e) => {
    e.preventDefault();

    const response = await axios.post("/api/stripe/v1/coupons", {
      percentOff: inputs.percentOff,
      duration: "forever",
    });
    if (response.data.raw) {
      toast.error(response.data.raw.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      if (response.data.url) window.location = response.data.url;
      else window.location.reload();
    }
    e.target.reset();
  };

  // Send form data to server to delete promo
  const deactivatePromo = async (e) => {
    e.preventDefault();
    let id = e.target.promo.value;
    const response = await axios.post(`/api/stripe/v1/promotion_codes/${id}`);
    if (response.data.url) window.location = response.data.url;
    else window.location.reload();
  };

  // Send form data to server to delete promo
  const deleteCoupon = async (e) => {
    e.preventDefault();
    let id = e.target.coupon.value;
    const response = await axios.delete(`/api/stripe/v1/coupons/${id}`);
    if (response.data.url) window.location = response.data.url;
    else window.location.reload();
  };

  return (
    <div className="admin_forms justify-center">
      {/* PROMOCODE SECTION */}
      <div>
        <div className="adminpagetitle">
          <h2 className="text-lg font-semibold">Promo Codes</h2>
        </div>
        <form
          id="addScentForm"
          className="addScentForm mx-auto pt-2"
          onSubmit={uploadPromo}
        >
          <div className="adminpagetitle">
            <h2 className="text-lg font-semibold">Add a Promo Code</h2>
          </div>
          <div className="mb-3">
            <label>PromoCode</label>
            <input
              className="!mb-1"
              type="text"
              id="promo"
              name="promo"
              placeholder="Promo Code"
              onChange={handleChange}
              required
            />
          </div>
          <p>
            Coupon {"(%Off)"}:
            <select
              className="ml-4 !mb-3"
              name="coupon"
              required
              style={{ border: "1px gray solid", borderRadius: "3px" }}
            >
              <option value="">Choose a Coupon</option>
              {coupons.map((x) => (
                <option key={x.id} value={x.id}>
                  {x.percent_off}% Off
                </option>
              ))}
            </select>
          </p>

          <div className="mb-3">
            <label>Minimum Amount for Code to Apply</label>
            <input
              type="text"
              id="minAmount"
              name="minAmount"
              defaultValue={0.0}
              onChange={handleChange}
            />
          </div>
          <button type="submit" value="Upload">
            Submit
          </button>
        </form>

        <form
          id="addScentForm"
          className="addScentForm mx-auto pt-2"
          onSubmit={deactivatePromo}
        >
          <div className="adminpagetitle">
            <h2 className="text-lg font-semibold ">Deactivate a Promo Code</h2>
          </div>
          <p>
            Active Promo Codes:
            <select
              className="ml-4"
              name="promo"
              required
              value={promoChosen}
              onChange={(e) => setPromoChosen(e.target.value)}
              style={{ border: "1px gray solid", borderRadius: "3px" }}
            >
              <option value="">Choose a Promo</option>
              {promos.map((x) => (
                <option key={x.id} value={x.id}>
                  {x.code}
                </option>
              ))}
            </select>
          </p>
          <button type="submit" value="Upload">
            Deactivate Promo Code
          </button>
        </form>
      </div>

      {/* COUPON SECTION */}
      <div>
        <div className="adminpagetitle">
          <h2 className="text-lg font-semibold pt-5">Coupons</h2>
        </div>
        <form
          id="addScentForm"
          className="addScentForm mx-auto pt-2"
          onSubmit={uploadCoupon}
        >
          <div className="adminpagetitle">
            <h2 className="text-lg font-semibold">Add a Coupon</h2>
          </div>
          <div className="mb-3">
            <p>Percent Off {"(Apply this to a Promo Code)"}</p>
            <input
              type="text"
              id="percentOff"
              name="percentOff"
              placeholder="Percent Off"
              onChange={handleChange}
              required
            />
          </div>
          <button type="submit" value="Upload">
            Submit
          </button>
        </form>
        <form
          id="addScentForm"
          className="addScentForm mx-auto pt-2"
          onSubmit={deleteCoupon}
        >
          <div className="adminpagetitle">
            <h2 className="text-lg font-semibold">Delete a Coupon</h2>
          </div>
          <p>
            Delete a Coupon:
            <select
              className="ml-4"
              name="coupon"
              required
              style={{ border: "1px gray solid", borderRadius: "3px" }}
            >
              <option value="">Choose a Coupon</option>
              {coupons.map((x) => (
                <option key={x.id} value={x.id}>
                  {x.percent_off}% Off
                </option>
              ))}
            </select>
          </p>
          <button type="submit" value="Upload">
            Delete
          </button>
        </form>
      </div>
      <ToastContainer autoClose={3000} />
    </div>
  );
};

export default AddPromo;

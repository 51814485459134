import React, { useState } from "react";
import "../pages/Adminpage.css";
import Pagination from "./Pagination";
import { filterRows, paginateRows, sortRows } from "./helpers";

const Table = (props) => {
  let columns = props.columns,
    rows = props.rows;

  // Set up pagination and filtering
  const [activePage, setActivePage] = useState(1);
  const [filters, setFilters] = useState({});
  const [sort, setSort] = useState({ order: "", orderBy: "id" });
  const rowsPerPage = 10;

  const filteredRows = filterRows(rows, filters);
  const sortedRows = sortRows(filteredRows, sort);
  const calculatedRows = paginateRows(sortedRows, activePage, rowsPerPage);

  const count = filteredRows.length;
  const totalPages = Math.ceil(count / rowsPerPage);

  const handleSearch = (value, accessor) => {
    setActivePage(1);
    if (value) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [accessor]: value,
      }));
    } else {
      setFilters((prevFilters) => {
        const updatedFilters = { ...prevFilters };
        delete updatedFilters[accessor];

        return updatedFilters;
      });
    }
    console.log(filters);
  };

  const handleSort = (accessor) => {
    setActivePage(1);
    setSort((prevSort) => ({
      order:
        prevSort.order === "" && prevSort.orderBy === accessor
          ? "asc"
          : prevSort.order === "asc" && prevSort.orderBy === accessor
          ? "desc"
          : "",
      orderBy: accessor,
    }));
  };

  const clearAll = () => {
    setSort({ order: "", orderBy: "id" });
    setActivePage(1);
    setFilters({});
  };

  return (
    <>
      <div className="tbl-header">
        <table cellPadding="0" cellSpacing="0" border="0">
          <thead>
            <tr>
              {columns.map((column) => {
                const sortIcon = () => {
                  if (column.accessor === sort.orderBy) {
                    if (sort.order === "asc") {
                      return "⬆️";
                    } else if (sort.order === "desc") {
                      return "⬇️";
                    }
                    return "";
                  } else {
                    return "";
                  }
                };
                return (
                  <th key={column.accessor}>
                    <span
                      onClick={() => handleSort(column.accessor)}
                      style={{ cursor: "pointer" }}
                    >
                      {column.label}
                      {sortIcon()}
                    </span>
                  </th>
                );
              })}
              <th>Edit</th>
            </tr>
            <tr style={{ border: "none", backgroundColor: "white" }}>
              {columns.map((column, index) => {
                return (
                  <th key={index}>
                    <input
                      key={`${column.accessor}-search`}
                      type="search"
                      placeholder={`Search ${column.label}`}
                      value={filters[column.accessor] || ""}
                      onChange={(e) =>
                        handleSearch(e.target.value, column.accessor)
                      }
                    />
                  </th>
                );
              })}
              <th key="blank">N/A</th>
            </tr>
          </thead>
        </table>
      </div>
      <div className="tbl-content">
        <table>
          <tbody>
            {calculatedRows.map((row) => {
              return (
                <tr key={row._id}>
                  {columns.map((column) => {
                    if (column.accessor === "imageUrl") {
                      return (
                        <td key={column.accessor}>
                          <img src={row[column.accessor]} alt="candleimg" />
                        </td>
                      );
                    }
                    return (
                      <td key={column.accessor}>{row[column.accessor]}</td>
                    );
                  })}
                  <td>
                    <i
                      className="fas fa-edit"
                      onClick={() => {
                        props.openModal();
                        props.setModalData(row);
                        props.setCandleInputs(row);
                      }}
                    ></i>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Pagination
        activePage={activePage}
        count={count}
        rowsPerPage={rowsPerPage}
        totalPages={totalPages}
        setActivePage={setActivePage}
      />
    </>
  );
};

export default Table;

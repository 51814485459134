import * as actionTypes from "../constants/userConstants";
import axios from "axios";
import Cookies from "js-cookie";

export const userPost = (inputs) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.ADD_USER_REQUEST });

    const { data } = await axios.post("/api/auth/signup", inputs);

    dispatch({
      type: actionTypes.ADD_USER_SUCCESS,
      payload: data,
    });
    window.location = data.url;
  } catch (error) {
    dispatch({
      type: actionTypes.ADD_USER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const userLogin = (inputs) => async (dispatch, getState) => {
  try {
    // console.log("actions: input", inputs);

    const { data } = await axios.post("/api/auth/login", inputs, {
      withCredentials: true,
    });

    dispatch({
      type: actionTypes.LOGIN_USER_SUCCESS,
      payload: data.user !== undefined ? data.user : null,
    });

    Cookies.set("ls_u", JSON.stringify(getState().user.user));
    window.location = data.url;
    if (data.user === undefined) sessionStorage.setItem("showmsg", data.msg);
  } catch (error) {
    dispatch({
      type: actionTypes.ADD_USER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.msg
          : error.message,
    });
  }
};

export const userLogout = (user) => async (dispatch, getState) => {
  try {
    const { data } = await axios.post("/api/auth/logout", { user: user });

    dispatch({
      type: actionTypes.LOGOUT_USER,
      payload: data.user,
    });

    Cookies.set("ls_u", JSON.stringify(getState().user.user));
    window.location = data.url;
  } catch (error) {
    console.log(error);
  }
};

import * as actionTypes from "../constants/scentConstants";

export const getScentsReducer = (state = { scents: [] }, action) => {
  switch (action.type) {
    case actionTypes.GET_SCENTS_REQUEST:
      return {
        obtaining: true,
        scents: [],
      };
    case actionTypes.GET_SCENTS_SUCCESS:
      return {
        obtaining: false,
        scents: action.payload,
      };

    case actionTypes.GET_SCENTS_FAIL:
      return {
        obtaining: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const postScentReducer = (state = { scents: {} }, action) => {
  switch (action.type) {
    case actionTypes.POST_SCENT_REQUEST:
      return {
        obtaining: true,
      };

    case actionTypes.POST_SCENT_SUCCESS:
      return {
        obtaining: false,
        candle: action.payload,
      };

    case actionTypes.POST_SCENT_FAIL:
      return {
        obtaining: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const deleteScentReducer = (state = { scents: {} }, action) => {
  switch (action.type) {
    case actionTypes.DELETE_SCENT_REQUEST:
      return {
        loading: true,
      };

    case actionTypes.DELETE_SCENT_SUCCESS:
      return {
        loading: false,
        candle: action.payload,
      };

    case actionTypes.DELETE_SCENT_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

import axios from "axios";

const Paybutton = ({ cartItems }) => {
  const handleCheckout = async () => {
    try {
      const response = await axios.post("/api/stripe/create-checkout-session", {
        cartItems,
      });

      if (response.data.url) {
        window.location.href = response.data.url;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <button
        disabled={cartItems.length === 0}
        onClick={() => handleCheckout()}
      >
        Check out
      </button>
    </>
  );
};

export default Paybutton;

import './Footer.css'

const Footer = () => {
  return (
    <footer>
        <ul className="icons">
            <li><a href="https://www.instagram.com/lyndseys_scents/" className="fa-brands fa-instagram"><span>Instagram</span></a></li>
            <li><span>lyndseysscents@gmail.com</span></li> 
        </ul>
        <ul className="copyright">
            <li>&copy; 2021 Lyndsey's Scents</li><li>Design: S.Guo</li>
        </ul>
    </footer>
  )
}

export default Footer
import "../Adminpage.css";
import { useDispatch } from "react-redux";
import React, { useState } from "react";

// Actions
import { postCandles } from "../../redux/actions/candleActions";

const AddCandle = () => {
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState({});
  const [file, setFile] = useState(null);

  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  // Send form data to server to create new candle
  const candleForm = [
    { label: "Candle Name", id: "name", placeholder: "Candle Name" },
    { label: "Size", id: "size", placeholder: "Size" },
    { label: "Price", id: "price", placeholder: "Price" },
    { label: "Count In Stock", id: "countInStock", placeholder: "Default: 5" },
    { label: "Scent", id: "scent", placeholder: "Custom" },
  ];

  const uploadCandle = (e) => {
    // console.log(file)
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", inputs.name);
    formData.append("size", inputs.size);
    formData.append("price", inputs.price);
    formData.append("countInStock", inputs.countInStock);
    formData.append("scent", inputs.scent);
    formData.append(
      "craftSelection",
      inputs.craftSelection === "on" ? true : false
    );
    formData.append("classic", inputs.classic === "on" ? true : false);
    formData.append("nonbooze", inputs.nonbooze === "on" ? true : false);
    formData.append("image", file);

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    dispatch(postCandles(formData, config));
    e.target.reset();
    setFile(null);
    window.location.reload();
  };

  return (
    <div className="admin_forms justify-center">
      <div>
        <div className="adminpagetitle">
          <h2 className="text-lg font-semibold">Add a Candle</h2>
        </div>
        <form
          id="addCandleForm"
          className="addCandleForm mx-auto"
          onSubmit={uploadCandle}
        >
          {candleForm.map((input) => (
            <div className="mb-3" key={input.id}>
              <label className="font-bold">{input.label}</label>
              <input
                type="text"
                id={input.id}
                name={input.id}
                placeholder={input.placeholder}
                onChange={handleChange}
              />
            </div>
          ))}
          <div className="candleTags">
            <label className="font-bold">Craft Selection</label>
            <input
              type="checkbox"
              id="craftSelection"
              name="craftSelection"
              onChange={handleChange}
            />

            <label className="font-bold">Classic</label>
            <input
              type="checkbox"
              id="classic"
              name="classic"
              onChange={handleChange}
            />

            <label className="font-bold">Non-Booze</label>
            <input
              type="checkbox"
              id="nonbooze"
              name="nonbooze"
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label className="font-bold">Image</label>
            <input
              type="file"
              id="image"
              name="image"
              onChange={(e) => setFile(e.target.files[0])}
            />
          </div>
          <button type="submit" value="Upload" className="submit_btn">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddCandle;

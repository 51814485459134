import "./Contactpage.css";
import { useState } from "react";
import axios from "axios";

const Contactpage = () => {
  const [inputs, setInputs] = useState({});

  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const sendMessage = async (e) => {
    e.preventDefault();

    const { data } = await axios.post("/api/auth/contactus", inputs);

    console.log(data);
    e.target.reset();
  };

  return (
    <div className="contactpage">
      <div className="contact-container">
        {/* <div>
          <img
            style={{ width: "350px" }}
            src={require("./images/marketpic.jpg")}
            alt=""
          />
        </div> */}
        <div>
          <form action="action_page.php" onSubmit={sendMessage}>
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Your name.."
              onChange={handleChange}
            />

            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Email Address"
              onChange={handleChange}
            />

            <label htmlFor="subject">Subject</label>
            <textarea
              id="subject"
              name="subject"
              placeholder="Write something.."
              onChange={handleChange}
            ></textarea>

            <input type="submit" value="Submit" id="contactsubmit" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contactpage;

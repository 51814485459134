import * as actionTypes from '../constants/candleConstants';
import axios from 'axios';

export const getCandles = () => async (dispatch) => {
    try {
        dispatch({type: actionTypes.GET_PRODUCTS_REQUEST});
       
        const{data} = await axios.get('/api/candles');
        
        dispatch({
            type: actionTypes.GET_PRODUCTS_SUCCESS, 
            payload: data.candles
        })
    } catch (error) {
        dispatch({
            type: actionTypes.GET_PRODUCTS_FAIL,
            payload: 
                error.response && error.response.data.message ? error.response.data.message: error.message,
        })
        
    }
}

export const getCandleDetails = (id) => async (dispatch) => {
    try {
        dispatch({type: actionTypes.GET_PRODUCT_DETAILS_REQUEST});

        const { data } = await axios.get(`/api/candles/${id}`);

        dispatch({
            type: actionTypes.GET_PRODUCT_DETAILS_SUCCESS, 
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: actionTypes.GET_PRODUCTS_FAIL,
            payload: 
                error.response && error.response.data.message ? error.response.data.message: error.message,
        })
    }
}

export const removeCandlesDetails = () => (dispatch) => {
    dispatch({
        type: actionTypes.GET_PRODUCT_DETAILS_RESET
    })
}

export const postCandles = (formData, config) => async (dispatch) => {
    try {
        dispatch({type: actionTypes.POST_PRODUCT_REQUEST});

        const {data} = await axios.post('/api/candles', formData, config);

        dispatch({
            type: actionTypes.POST_PRODUCT_SUCCESS, 
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: actionTypes.POST_PRODUCT_FAIL,
            payload: 
                error.response && error.response.data.message ? error.response.data.message: error.message,
        })
        
    }
}

export const putCandles = (formData, config, id) => async (dispatch) => {
    try {
        dispatch({type: actionTypes.PUT_PRODUCT_REQUEST});

        const {data} = await axios.put(`/api/candles/${id}`, formData, config);

        dispatch({
            type: actionTypes.PUT_PRODUCT_SUCCESS, 
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: actionTypes.PUT_PRODUCT_FAIL,
            payload: 
                error.response && error.response.data.message ? error.response.data.message: error.message,
        })
        
    }
}

export const deleteCandle = (id) => async (dispatch) => {
    try {
        dispatch({type: actionTypes.DELETE_PRODUCT_REQUEST});

        const {data} = await axios.delete(`/api/candles/${id}`);

        dispatch({
            type: actionTypes.DELETE_PRODUCT_SUCCESS, 
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: actionTypes.DELETE_PRODUCT_FAIL,
            payload: 
                error.response && error.response.data.message ? error.response.data.message: error.message,
        })
        
    }
}
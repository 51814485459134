import * as actionTypes from "../constants/cartConstants";
import axios from "axios";

export const addToCart =
  (id, qty, scentChosen) => async (dispatch, getState) => {
    //fetch candle data based on candle id
    const { data } = await axios.get(`/api/candles/${id}`);

    // send payload to reducer with candle data- currently only storing id, scent, and qty
    dispatch({
      type: actionTypes.ADD_TO_CART,
      payload: {
        product: data._id,
        name: data.name,
        imageUrl: data.imageUrl,
        price: data.price,
        countInStock: data.countInStock,
        scent: scentChosen,
        size: data.size,
        qty: Number(qty),
      },
    });

    // set localstorage to cart state with id, scent, and qty
    console.log(getState().cart.cartItems);
    localStorage.setItem(
      "cart",
      JSON.stringify(
        getState().cart.cartItems.map((el) => {
          return {
            product: el.product,
            scent: el.scent,
            qty: el.qty,
          };
        })
      )
    );
  };

export const removeFromCart = (id) => (dispatch, getState) => {
  dispatch({
    type: actionTypes.REMOVE_FROM_CART,
    payload: id,
  });

  // set localstorage to cart state with id, scent, and qty
  localStorage.setItem(
    "cart",
    JSON.stringify(
      getState().cart.cartItems.map((el) => {
        return {
          product: el.product,
          scent: el.scent,
          qty: el.qty,
        };
      })
    )
  );
};

export const resetCart = () => (dispatch, getState) => {
  dispatch({
    type: actionTypes.CART_RESET,
  });

  // set localstorage to cart state with id, scent, and qty
  localStorage.setItem(
    "cart",
    JSON.stringify(
      getState().cart.cartItems.map((el) => {
        return {
          product: el.product,
          scent: el.scent,
          qty: el.qty,
        };
      })
    )
  );
};

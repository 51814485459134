import "./Candle.css";
import { Link } from "react-router-dom";
// import {Transformation} from '@cloudinary/url-gen'

const Candle = ({
  imageUrl,
  name,
  price,
  size,
  scent,
  productId,
  countInStock,
}) => {
  return (
    <div className="candle">
      <div className="content_img">
        <img src={imageUrl} quality="auto" alt={name}></img>
        <Link to={`/candles/${productId}`}>
          <div>View Candle</div>
        </Link>
      </div>
      <div className="product_info">
        <p className="info_name">{name}</p>
        <p className="info_size">Size: {size}</p>
        <p className="info_scent">Scent: {scent}</p>
        {countInStock > 0 ? (
          <p className="info_price">${price}</p>
        ) : (
          <p className="info_price">Out of Stock</p>
        )}
      </div>
    </div>
  );
};

export default Candle;

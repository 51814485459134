import "./CartItem.css";
import { Link } from "react-router-dom";

const CartItem = ({ item, qtyChangeHandler, removeHandler, changeCart }) => {
  return (
    <div
      className={`cartitem ${
        changeCart.loading && changeCart.id === item._id ? "cartload" : ""
      }`}
    >
      <div className="cartitem_image">
        <img src={item.imageUrl} alt={item.name}></img>
      </div>
      <div>
        <Link to={`/candles/${item._id}`} className="cartitem_name">
          <p>
            {item.name}/ {item.size}
          </p>
        </Link>
        <span className="cartitem_scent">Scent: {item.scent}</span>
      </div>

      <p className="cartitem_price">${item.price}</p>

      <select
        className={`cartItem_select ${
          changeCart.loading && changeCart.id === item._id ? "cartload" : ""
        }`}
        value={item.qty}
        onChange={(e) => qtyChangeHandler(item._id, e.target.value, item.scent)}
      >
        {[...Array(item.countInStock).keys()].map((x) => (
          <option key={x + 1}>{x + 1}</option>
        ))}
      </select>

      <button
        className="cartitem_deleteBtn"
        onClick={() => removeHandler(item._id)}
      >
        <i className="fas fa-trash"></i>
      </button>
    </div>
  );
};

export default CartItem;

export const GET_PRODUCTS_REQUEST = "GET_PRODUCTS_REQUEST"
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS"
export const GET_PRODUCTS_FAIL = "GET_PRODUCTS_FAIL"

export const GET_PRODUCT_DETAILS_REQUEST = "GET_PRODUCT_DETAILS_REQUEST"
export const GET_PRODUCT_DETAILS_SUCCESS = "GET_PRODUCT_DETAILS_SUCCESS"
export const GET_PRODUCT_DETAILS_FAIL = "GET_PRODUCT_DETAILS_FAIL"
export const GET_PRODUCT_DETAILS_RESET = "GET_PRODUCT_DETAILS_RESET"

export const POST_PRODUCT_REQUEST = "POST_PRODUCT_REQUEST"
export const POST_PRODUCT_SUCCESS = "POST_PRODUCT_SUCCESS"
export const POST_PRODUCT_FAIL = "POST_PRODUCT_FAIL"

export const PUT_PRODUCT_REQUEST = "PUT_PRODUCT_REQUEST"
export const PUT_PRODUCT_SUCCESS = "PUT_PRODUCT_SUCCESS"
export const PUT_PRODUCT_FAIL = "PUT_PRODUCT_FAIL"

export const DELETE_PRODUCT_REQUEST = "DELETE_PRODUCT_REQUEST"
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS"
export const DELETE_PRODUCT_FAIL = "DELETE_PRODUCT_FAIL"
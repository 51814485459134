import "./ShopPage.css";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import Pagination from "../components/Pagination";

// Components
import Candle from "../components/Candle";

// Actions
import { getCandles as listCandles } from "../redux/actions/candleActions";
import { filterRows, paginateRows, sortRows } from "../components/helpers";

const filtersCol = [
  { accessor: "craftselection", label: "Craft Selection" },
  { accessor: "classic", label: "The Classics" },
  { accessor: "nonbooze", label: "Non Booze" },
];

const sortsCol = [
  { accessor: "", label: "Sort By", order: "" },
  { id: 1, accessor: "name", label: "Name A-Z", order: "asc" },
  { id: 2, accessor: "name", label: "Name Z-A", order: "desc" },
  { id: 3, accessor: "price", label: "Price (low-high)", order: "asc" },
  { id: 4, accessor: "price", label: "Price (high-low)", order: "desc" },
];

const ShopPage = () => {
  const [deviceSize, changeDeviceSize] = useState(window.innerWidth);
  const dispatch = useDispatch();

  useEffect(() => {
    const resizeW = () => changeDeviceSize(window.innerWidth);

    window.addEventListener("resize", resizeW); // Update the width on resize
    return () => window.removeEventListener("resize", resizeW);
  });

  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({});
  const [sort, setSort] = useState({ order: "", orderBy: "id" });
  const ITEMS_PER_PAGE = deviceSize >= 786 ? 15 : 12;
  const itemsSeen = page * ITEMS_PER_PAGE;

  const getCandles = useSelector((state) => state.getCandles);
  const { candles, loading, error } = getCandles;

  useEffect(() => {
    dispatch(listCandles());
  }, [dispatch]);

  const filteredCandles = filterRows(candles, filters);
  const sortedCandles = sortRows(filteredCandles, sort);
  const displayCandles = paginateRows(sortedCandles, page, ITEMS_PER_PAGE);

  const count = filteredCandles.length;
  const totalPages = Math.ceil(count / ITEMS_PER_PAGE);

  const handleSearch = (value, accessor) => {
    setPage(1);
    console.log(value, accessor);
    if (value) {
      if (filters[accessor]) {
        setFilters((prevFilters) => {
          const updatedFilters = { ...prevFilters };
          delete updatedFilters[accessor];

          return updatedFilters;
        });
      } else {
        // setFilters((prevFilters) => ({
        //   ...prevFilters,
        //   [accessor]: value,
        // }));
        setFilters({ [accessor]: value });
      }
    }
  };

  const handleSort = (e) => {
    setPage(1);
    let sortType = sortsCol[e];
    setSort({ order: sortType.order, orderBy: sortType.accessor });
  };

  const changePage = ({ selected }) => {
    setPage(selected);
  };

  const clearAll = () => {
    setSort({ order: "", orderBy: "id" });
    setPage(1);
    setFilters({});
  };

  return (
    <div className="shop">
      {deviceSize >= 786 && (
        <div className="filter_bar ml-auto">
          <h1 className="text-3xl">Filter By</h1>
          <h2 className="text-l font-semibold">Collections</h2>
          {filtersCol.map((column, index) => {
            return (
              <button
                key={index}
                value={true}
                onClick={(e) => handleSearch(e.target.value, column.accessor)}
                className={
                  filters[column.accessor] ? "text-l font-extrabold" : ""
                }
              >
                {column.label}
              </button>
            );
          })}
          <h2 className="text-l font-semibold">Scented/ Pre-Made</h2>
          <button onClick={() => handleSearch("Not Custom", "scent")}>
            Scented
          </button>
          <button onClick={() => handleSearch("Custom", "scent")}>
            Custom
          </button>

          <h2 className="text-l font-semibold">Clear Filters</h2>
          <button onClick={() => clearAll()}>Clear All</button>
        </div>
      )}
      <div className="shoppage">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h2 className="shoppage_title text-2xl font-bold">
            Shop for Candles
          </h2>

          <select
            className="sort-select"
            onChange={(e) => handleSort(e.target.value)}
          >
            {sortsCol.map((column, index) => {
              return (
                <option key={index} value={column.id}>
                  {column.label}
                </option>
              );
            })}
          </select>
        </div>

        <div className="shoppage_candles">
          {loading ? (
            <h2>Loading... </h2>
          ) : error ? (
            <h2>{error}</h2>
          ) : (
            displayCandles.map((candle) => (
              <Candle
                key={candle._id}
                productId={candle._id}
                name={candle.name}
                price={candle.price}
                size={candle.size}
                scent={candle.scent}
                imageUrl={candle.imageUrl}
                countInStock={candle.countInStock}
              />
            ))
          )}
        </div>
        {/* <div className="paginate">
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={"paginationBtns"}
            previousLinkClassName={"previousBtn"}
            nextLinkClassName={"nextBtn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
            initialPage={1}
          />
        </div> */}
        <Pagination
          activePage={page}
          count={count}
          rowsPerPage={ITEMS_PER_PAGE}
          totalPages={totalPages}
          setActivePage={setPage}
        />
      </div>
    </div>
  );
};

export default ShopPage;

// first try to paginate through server...
// useEffect(()=>{
//   if(pagination){
//     // console.log(candles, pagination)
//     setPageCount(Math.ceil(pagination.pageCount))
//   }
// }, [ pagination])

// const handlePrevious = () => {
//   setPage((p)=>{
//     if(p===1) return p;
//     return p-1
//   })
//   window.location.href = `/shop?page=${page}`
// }

// const handleNext = () => {
//   setPage((p) =>{
//     console.log(p)
//     if(p === pageCount) return p
//     return p+1
//   })
//   window.location.href = `/shop?page=${page}`
// }

// console.log(candles, pagination)

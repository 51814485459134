import "../Adminpage.css";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";
import {
  getCandles,
  putCandles,
  deleteCandle,
} from "../../redux/actions/candleActions";
import Table from "../../components/Table";

const EditCandle = () => {
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState({});
  const [file, setFile] = useState(null);

  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  useEffect(() => {
    dispatch(getCandles());
  }, [dispatch]);

  // sets scrollbar position to last saved position
  useEffect(() => {
    let position = sessionStorage.getItem("scrollPos"),
      element = document.getElementById("table-content");
    if (position !== null && element !== null) {
      let num = Number(position);
      element.scrollTop = num;
    }
  });

  // Get candles to edit
  const getAllCandles = useSelector((state) => state.getCandles);
  const { candles, loading, error } = getAllCandles;

  const setCandleInputs = (candle) => {
    console.log(candle);
    setInputs({
      _id: candle._id,
      name: candle.name,
      size: candle.size,
      price: candle.price,
      countInStock: candle.countInStock,
      imageUrl: candle.imageUrl,
      scent: candle.scent,
      craftselection: candle.craftselection,
      classic: candle.classic,
      nonbooze: candle.nonbooze,
    });
  };

  // Send form data to server to edit existing candle
  const editCandle = (e) => {
    e.preventDefault();
    console.log(e);

    const formData = new FormData();
    formData.append("name", inputs.name);
    formData.append("size", inputs.size);
    formData.append("price", inputs.price);
    formData.append("countInStock", inputs.countInStock);
    formData.append("scent", inputs.scent);
    formData.append(
      "craftSelection",
      inputs.craftSelection === "on" ? true : false
    );
    formData.append("classic", inputs.classic === "on" ? true : false);
    formData.append("nonbooze", inputs.nonbooze === "on" ? true : false);
    formData.append("image", file);

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    dispatch(putCandles(formData, config, inputs._id));
    e.target.reset();
    setInputs({});
    setFile(null);
    dispatch(getCandles());
    closeModal();
    window.location.reload();
  };

  const deleteHandler = (e) => {
    e.preventDefault();
    dispatch(deleteCandle(inputs._id));
    closeModal();
    dispatch(getCandles());
  };

  // setup Modal for editing
  Modal.setAppElement("#root");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const getModalWidth = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 600) return "95%";
    else if (screenWidth < 1000) return "70%";
    else return "50%";
  };
  const getModalHeight = () => {
    if (window.innerWidth < 600 && window.innerHeight < 1000) return "95%";
    else return;
  };
  const modalStyle = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, .75)",
    },
    content: {
      position: "fixed",
      width: getModalWidth(),
      height: getModalHeight(),
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      background: "white",
      overflow: "auto",
      WebkitOverflowScrolling: "touch",
      borderRadius: "4px",
      outline: "2px solid dimgray",
    },
  };
  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
    setInputs({});
    setFile(null);
  };

  // const scrollSave = (e) => {
  //   // console.log(e)
  //   sessionStorage.setItem("scrollPos", e.target.scrollTop);
  // };

  const columns = [
    { accessor: "name", label: "Name" },
    { accessor: "imageUrl", label: "Image" },
    { accessor: "price", label: "Price" },
    { accessor: "size", label: "Size" },
    { accessor: "countInStock", label: "Count in Stock" },
  ];

  const rows = candles.map((candle) => {
    return {
      _id: candle._id,
      name: candle.name,
      size: candle.size,
      price: candle.price,
      countInStock: candle.countInStock,
      imageUrl: candle.imageUrl,
      scent: candle.scent,
      craftselection: candle.craftselection,
      classic: candle.classic,
      nonbooze: candle.nonbooze,
    };
  });

  return (
    <>
      <div className="admin-edit-page">
        <div className="adminpagetitle">
          <h2 className="text-lg font-semibold">Edit a Candle</h2>
        </div>
        <div className="editcandles">
          {loading ? (
            <h2>Loading... </h2>
          ) : error ? (
            <h2>{error}</h2>
          ) : (
            <Table
              columns={columns}
              rows={rows}
              openModal={openModal}
              setModalData={setModalData}
              setCandleInputs={setCandleInputs}
            />
          )}
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="editCandle"
        style={modalStyle}
        className="details-modal"
      >
        {modalData !== null ? (
          <form className="modalData" onSubmit={editCandle}>
            <div className="modalLeft">
              <p>Change Image for: {modalData.name}</p>
              <img src={modalData.imageUrl} alt={modalData.name}></img>
              <input
                type="file"
                id="image"
                name="image"
                onChange={(e) => setFile(e.target.files[0])}
              />
            </div>

            <div className="modalRight">
              <p>
                Name:{" "}
                <input
                  type="text"
                  name="name"
                  placeholder={modalData.name}
                  onChange={handleChange}
                />
              </p>
              <p>
                {" "}
                Price:{" "}
                <input
                  type="text"
                  name="price"
                  placeholder={modalData.price}
                  onChange={handleChange}
                />
              </p>
              <p>
                {" "}
                Size:{" "}
                <input
                  type="text"
                  name="size"
                  placeholder={modalData.size}
                  onChange={handleChange}
                />
              </p>
              <p>
                {" "}
                Count In Stock:{" "}
                <input
                  type="text"
                  name="countInStock"
                  placeholder={modalData.countInStock}
                  onChange={handleChange}
                />
              </p>
              <button type="submit" value="Upload">
                Update Fields
              </button>
              <button type="cancel" onClick={closeModal}>
                Cancel
              </button>
              <button onClick={deleteHandler}>Delete</button>
            </div>
          </form>
        ) : null}
      </Modal>
    </>
  );
};

export default EditCandle;

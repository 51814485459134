import React from "react";
import { NavLink } from "react-router-dom";

const Sidebar = () => {
  const adminTasks = [
    { task: "addcandle", name: "Add Candle" },
    { task: "editcandle", name: "Edit/ Delete Candle" },
    { task: "addscent", name: "Add/ Delete Scent" },
    { task: "addpromo", name: "Add/ Delete Promo Code" },
  ];

  const activeLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-white text-md m-2 bg-indigo-400";
  const normalLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2";

  return (
    <div className="ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10">
      <div>
        <NavLink
          to={`/admindashboard/`}
          className={({ isActive }) => (isActive ? activeLink : normalLink)}
        >
          Home
        </NavLink>
      </div>
      {adminTasks.map((task) => (
        <div key={task.task}>
          <NavLink
            to={`/admindashboard/${task.task}`}
            className={({ isActive }) => (isActive ? activeLink : normalLink)}
          >
            {task.name}
          </NavLink>
        </div>
      ))}
    </div>
  );
};

export default Sidebar;

export const GET_SCENTS_REQUEST = "GET_SCENTS_REQUEST";
export const GET_SCENTS_SUCCESS = "GET_SCENTS_SUCCESS";
export const GET_SCENTS_FAIL = "GET_SCENTS_FAIL";

export const POST_SCENT_REQUEST = "POST_SCENT_REQUEST";
export const POST_SCENT_SUCCESS = "POST_SCENT_SUCCESS";
export const POST_SCENT_FAIL = "POST_SCENT_FAIL";

export const DELETE_SCENT_REQUEST = "DELETE_SCENT_REQUEST";
export const DELETE_SCENT_SUCCESS = "DELETE_SCENT_SUCCESS";
export const DELETE_SCENT_FAIL = "DELETE_SCENT_FAIL";

import "./AdminDashboard.css";
import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";

import Sidebar from "../components/Sidebar";
import AdminHome from "./adminpages/AdminHome";
import AddCandle from "./adminpages/AddCandle";
import EditCandle from "./adminpages/EditCandle";
import AddScent from "./adminpages/AddScent";
import AddPromo from "./adminpages/AddPromo";

const adminTasks = [
  { task: "", name: "Home" },
  { task: "addcandle", name: "Add Candle" },
  { task: "editcandle", name: "Edit/ Delete Candle" },
  { task: "addscent", name: "Add/ Delete Scent" },
  { task: "addpromo", name: "Add/ Delete Promo Code" },
];
const AdminDashboard = () => {
  const activeMenu = true; // active menu currently unused- device size used instead
  const [deviceSize, changeDeviceSize] = useState(window.innerWidth);

  useEffect(() => {
    const resizeW = () => changeDeviceSize(window.innerWidth);

    window.addEventListener("resize", resizeW); // Update the width on resize
    return () => window.removeEventListener("resize", resizeW);
  });

  return (
    <div>
      <div className="flex relative dark:bg-main-dark-bg">
        {deviceSize > 768 ? (
          <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white">
            <Sidebar />
          </div>
        ) : (
          <div className="w-0 dark:bg-secondary-dark-bg">
            <Sidebar />
          </div>
        )}
        <div
          className={`dark:bg-main-bg bg-main-bg min-h-screen z-0 w-full ${
            activeMenu ? "md:ml-72" : "flex-2"
          }`}
        >
          {deviceSize <= 768 && (
            <div className="text-center pt-5">
              <select
                style={{ border: "1px gray solid" }}
                onChange={(e) => {
                  window.location = `/admindashboard/${
                    adminTasks[e.target.value].task
                  }`;
                }}
              >
                <option value="">Select a Tab</option>
                {adminTasks.map((task, index) => {
                  return (
                    <option key={index} value={index}>
                      {task.name}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
          <div>
            <Routes>
              {/* Dashboard */}
              <Route path="" element={<AdminHome />} />
              <Route path="addcandle" element={<AddCandle />} />
              <Route path="editcandle" element={<EditCandle />} />
              <Route path="addscent" element={<AddScent />} />
              <Route path="addpromo" element={<AddPromo />} />
              {/* Pages */}

              {/* Apps */}
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;

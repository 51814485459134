import * as actionTypes from "../constants/userConstants";

export const userReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case actionTypes.ADD_USER_REQUEST:
      return {
        loading: true,
      };

    case actionTypes.ADD_USER_SUCCESS:
      return {
        loading: false,
        user: action.payload,
      };

    case actionTypes.ADD_USER_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    // case actionTypes.LOGIN_USER_REQUEST:
    //     return {
    //         user: null
    //     };

    case actionTypes.LOGIN_USER_SUCCESS:
      return {
        user: action.payload,
      };

    case actionTypes.LOGIN_USER_FAIL:
      return {
        error: action.payload,
      };

    case actionTypes.LOGOUT_USER:
      return {
        user: action.payload,
      };

    default:
      return state;
  }
};

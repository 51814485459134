import './Faqpage.css'

const Faqpage = () => {
  return (
    <div className = 'faq'>
        <section className='faq-title'>
            <h1>FREQUENTLY ASKED QUESTIONS</h1>
            <h3>You Asked, We Answered</h3>
        </section>
        <section className='faq-questions'>
            <div>
                <h2>How do I go about making a custom candle?</h2>
                <p>There are two options... <br/>
                1{')'} If you like a can on our site, you can choose the scent if it is not pre-made.<br/>
                2{')'} If we do not have the can, you can contact us and we can either buy the can or you can bring/ship the can to us if it is ready to go. You will just need to tell us the scent for your creation.</p>
            </div>
            <div>
                <h2>Do you do cans that are not on the site?</h2>
                <p>Of course! Please contact us on the "Contact" page or via Instagram about your inquiry. There may be a small surcharge if we do not have the can in stock.</p>
            </div>
            <div>                
                <h2>If I have cans, can we ship or drop them off to you?</h2>
                <p>Absolutely. We love having cans ready to be recycled. Please get in touch with us on the "Contact" page or via Instagram, and we will make it happen.</p>
            </div>
            <div>
                <h2>Do the cans get hot?</h2>
                <p>These cans do get warm, so please be cautious when handling!</p>
            </div>
        </section>
    </div>
  )
}

export default Faqpage
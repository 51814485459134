import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function PrivateRoute({ children }) {
  const User = useSelector((state) => state.user);
  const { user } = User;

  const currentUser = user === process.env.REACT_APP_ADMIN;

  return currentUser ? children : <Navigate to="../login" />;
}

import "../Adminpage.css";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  postScent,
  getScents,
  deleteScent,
} from "../../redux/actions/scentActions";

const AddScent = () => {
  const dispatch = useDispatch();
  const [scentChosen, setScentChosen] = useState("");
  const [inputs, setInputs] = useState({});

  const allScents = useSelector((state) => state.getScents);
  const { scents } = allScents;

  useEffect(() => {
    dispatch(getScents());
  }, [dispatch]);

  // Send form data to server to create new scent
  const uploadScent = (e) => {
    e.preventDefault();
    const scent = { scent: inputs.scent };
    dispatch(postScent(scent));
    e.target.reset();
    window.location.reload();
  };

  // Send form data to server to delete scent
  const deleteHandler = (e) => {
    e.preventDefault();
    console.log(e.target.scent.value);
    dispatch(deleteScent(e.target.scent.value));
    dispatch(getScents());
    window.location.reload();
  };

  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  return (
    <div className="admin_forms justify-center">
      <div>
        <div className="adminpagetitle">
          <h2 className="text-lg font-semibold">Add a Scent</h2>
        </div>
        <form
          id="addScentForm"
          className="addScentForm mx-auto"
          onSubmit={uploadScent}
        >
          <div className="mb-3">
            <label>Scent Name</label>
            <input
              type="text"
              id="scent"
              name="scent"
              onChange={handleChange}
              required
            />
          </div>
          <button type="submit" value="Upload">
            Submit
          </button>
        </form>
      </div>
      <div>
        <div className="adminpagetitle">
          <h2 className="text-lg font-semibold">Delete a Scent</h2>
        </div>
        <form
          id="addScentForm"
          className="addScentForm mx-auto"
          onSubmit={deleteHandler}
        >
          <p>
            Scent:
            <select
              className="ml-4"
              name="scent"
              required
              value={scentChosen}
              onChange={(e) => setScentChosen(e.target.value)}
              style={{ border: "1px gray solid", borderRadius: "3px" }}
            >
              <option value="">Choose a Scent</option>
              {scents.map((x) => (
                <option key={x.scent} value={x._id}>
                  {x.scent}
                </option>
              ))}
            </select>
          </p>
          <button type="submit" value="Upload">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddScent;

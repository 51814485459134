import React from "react";

const AdminHome = () => {
  return (
    <div>
      <h1 style={{ fontSize: "32px", fontWeight: "bold" }}>
        Dashboard to Stripe
      </h1>
      <div>
        <a
          href="https://dashboard.stripe.com/dashboard"
          target="_blank"
          rel="noreferrer"
          className="button1"
        >
          Stripe Overview
        </a>
        <a
          href="https://dashboard.stripe.com/payments"
          target="_blank"
          rel="noreferrer"
          className="button1"
        >
          See all Payments
        </a>
        <a
          href="https://dashboard.stripe.com/customers"
          target="_blank"
          rel="noreferrer"
          className="button1"
        >
          View Customers
        </a>
      </div>
    </div>
  );
};

export default AdminHome;

import "./Navbar.css";
import logo from "./logo.PNG";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { userLogout } from "../redux/actions/userAction";

const Navbar = ({ click }) => {
  const dispatch = useDispatch();

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const User = useSelector((state) => state.user);
  const { user } = User;

  const getCartCount = () => {
    return cartItems.reduce((qty, item) => Number(item.qty) + qty, 0);
  };

  const logoutHandler = () => {
    dispatch(userLogout(user));
  };

  return (
    <div className="fixedTop">
      {/* <div className='announcement'>Free Shipping On All Orders Over $40</div>
       */}
      <div className="navbar">
        {/* Left - Navbar Links */}
        <div className="left">
          <ul className="navbar_links">
            <li>
              <Link to="/" className={`home_link links`}>
                <span>Home</span>
              </Link>
            </li>
            <li>
              <Link to="/shop" className="links">
                <span>Shop</span>
              </Link>
            </li>
            <li>
              <Link to="/faq" className="links">
                <span>FAQ</span>
              </Link>
            </li>
            <li>
              <Link to="/contact" className="links">
                <span>Contact</span>
              </Link>
            </li>
          </ul>
        </div>

        {/* Center - logo */}
        <div className={`center navbar_logo`}>
          <Link to="/">
            <img src={logo} alt="logo"></img>
          </Link>
        </div>

        {/* Right - Navbar Cart and Sign In */}
        <div className="right">
          <ul className="navbar_links">
            <li>
              <Link to="/cart" className={`cart_link links`}>
                <i
                  className="fa-solid fa-cart-shopping"
                  value={getCartCount()}
                  title="Cart"
                ></i>
              </Link>
            </li>
            {user !== null && user !== undefined ? (
              <li>
                <button className="logoutbtn" onClick={() => logoutHandler()}>
                  <i className="fa-solid fa-arrow-right-from-bracket fa-lg"></i>
                </button>
              </li>
            ) : (
              <li>
                <Link to="/login" className={`login_link links`}>
                  <i className="fa-regular fa-user" title="Login"></i>
                </Link>
              </li>
            )}
            {user === process.env.REACT_APP_ADMIN ? (
              <li>
                <Link to="/admindashboard/" className={`admin_link links`}>
                  <span>Admin</span>
                </Link>
              </li>
            ) : (
              <></>
            )}
          </ul>
        </div>

        {/* hamburger menu */}
        <div className="hamburger" onClick={click}>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;

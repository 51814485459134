import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import Cookies from "js-cookie";

//reducers
import { cartReducer } from "./reducers/cartReducers";
import {
  getCandlesReducer,
  getCandleDetailsReducer,
  postCandleReducer,
  putCandleReducer,
  deleteCandleReducer,
} from "./reducers/candleReducers";
import { userReducer } from "./reducers/userReducers";
import { postScentReducer, getScentsReducer } from "./reducers/scentReducers";

const reducer = combineReducers({
  cart: cartReducer,
  getCandles: getCandlesReducer,
  getCandleDetails: getCandleDetailsReducer,
  postCandle: postCandleReducer,
  putCandle: putCandleReducer,
  getScents: getScentsReducer,
  postScent: postScentReducer,
  deleteCandle: deleteCandleReducer,
  user: userReducer,
});

const middleware = [thunk];

const cartFromLocalStorage = localStorage.getItem("cart")
  ? JSON.parse(localStorage.getItem("cart"))
  : [];

const userFromCookie = Cookies.get("ls_u")
  ? JSON.parse(Cookies.get("ls_u"))
  : null;

const INITIAL_STATE = {
  cart: {
    cartItems: cartFromLocalStorage,
  },
  user: {
    user: userFromCookie,
  },
};

const store = createStore(
  reducer,
  INITIAL_STATE,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;

import "./App.css";
import { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

//Screens
import HomePage from "./pages/HomePage";
import CandlePage from "./pages/CandlePage";
import CartPage from "./pages/CartPage";
import ShopPage from "./pages/ShopPage";
import AdminDashboard from "./pages/AdminDashboard";
import Loginpage from "./pages/Loginpage";
import Signuppage from "./pages/Signuppage";
import Contactpage from "./pages/Contactpage";
import Successpage from "./pages/Successpage";

// Components
import Navbar from "./components/Navbar";
import Backdrop from "./components/Backdrop";
import Sidedrawer from "./components/Sidedrawer";
import Footer from "./components/Footer";
import Faqpage from "./pages/Faqpage";
import PrivateRoute from "./pages/PrivatePage";
function App() {
  const [sideToggle, setSideToggle] = useState(false);

  return (
    <div>
      <Router>
        <Navbar click={() => setSideToggle(true)} />
        <Sidedrawer show={sideToggle} setSideToggle={setSideToggle} />
        <Backdrop show={sideToggle} click={() => setSideToggle(false)} />
        <div className="mt-20">
          <Routes>
            <Route exact path="/" element={<HomePage />} />
            <Route exact path="/candles/:id" element={<CandlePage />} />
            <Route exact path="/cart" element={<CartPage />} />
            <Route exact path="/shop" element={<ShopPage />} />
            <Route exact path="/faq" element={<Faqpage />} />
            <Route exact path="/contact" element={<Contactpage />} />
            <Route
              exact
              path="/admindashboard/*"
              element={
                <PrivateRoute>
                  <AdminDashboard />
                </PrivateRoute>
              }
            />
            <Route exact path="/login" element={<Loginpage />} />
            <Route exact path="/signup" element={<Signuppage />} />
            <Route exact path="/success" element={<Successpage />} />
            <Route exact path="*" element={<HomePage />} />
          </Routes>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;

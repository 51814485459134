import * as actionTypes from "../constants/scentConstants";
import axios from "axios";

export const getScents = () => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_SCENTS_REQUEST });

    const { data } = await axios.get("/api/scents");

    dispatch({
      type: actionTypes.GET_SCENTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.GET_SCENTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const postScent = (input) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.POST_SCENT_REQUEST });

    const { data } = await axios.post("/api/scents", input);

    dispatch({
      type: actionTypes.POST_SCENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.POST_SCENT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteScent = (id) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.DELETE_SCENT_REQUEST });

    const { data } = await axios.delete(`/api/scents/${id}`);

    dispatch({
      type: actionTypes.DELETE_SCENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.DELETE_SCENT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

import './Signuppage.css'
import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { userPost } from '../redux/actions/userAction'

const Signuppage = () => {
    const dispatch = useDispatch()

    const [inputs, setInputs] = useState()

    const handleChange = (e) => {
        setInputs(prev =>{
            return {...prev, [e.target.name] : e.target.value}
        })
    }

    const signUpHandler = async (e) => {
        e.preventDefault()
        dispatch(userPost(inputs))
        e.target.reset()
    }

  return (
    <div className='signuppage'>

        <form className='signupform' onSubmit={signUpHandler}>
            <div className="signup-container">
                <div className='signuptitle'>
                    <h1>Sign Up</h1>
                </div>
                
                {/* <p>Please fill in this form to create an account.</p>
                <hr/> */}
                <label htmlFor="name"><b>Name</b></label>
                    <input type="text" placeholder="Enter Full Name" name="name" required onChange={handleChange}/>

                <label htmlFor="email"><b>Email</b></label>
                    <input type="text" placeholder="Enter Email" name="email" required onChange={handleChange}/>

                <label htmlFor="password"><b>Password</b></label>
                    <input type="password" placeholder="Enter Password" name="password" required onChange={handleChange}/>

                <label htmlFor="confirmPassword"><b>Confirm Password</b></label>
                    <input type="password" placeholder="Confirm Password" name="confirmPassword" required onChange={handleChange}/>

                {/* <label className='checkbox'>
                    <input type="checkbox" checked="checked" name="remember" /> Remember me
                </label> */}

                <div className="clearfix">
                    <button type="submit" className="signupbtn">Sign Up</button>
                </div>
            </div>
        </form>

    </div>
  )
}

export default Signuppage
import "./Loginpage.css";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { userLogin } from "../redux/actions/userAction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Loginpage = () => {
  const dispatch = useDispatch();

  const [inputs, setInputs] = useState();

  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const loginHandler = async (e) => {
    e.preventDefault();
    dispatch(userLogin(inputs));
  };

  if (sessionStorage.getItem("showmsg")) {
    toast.error(sessionStorage.getItem("showmsg"), { duration: 3000 });
    sessionStorage.removeItem("showmsg");
  }

  return (
    <div className="loginpage">
      <form className="loginform" onSubmit={loginHandler}>
        <div className="container">
          <label htmlFor="email">
            <b>Email</b>
          </label>
          <input
            type="email"
            placeholder="Enter Email"
            name="email"
            className="email-input"
            required
            onChange={handleChange}
          />

          <label htmlFor="password">
            <b>Password</b>
          </label>
          <input
            type="password"
            placeholder="Enter Password"
            name="password"
            className="password-input"
            required
            onChange={handleChange}
          />

          <button type="submit" className="loginbtn">
            Login
          </button>
          <span>Admin only: Guest sign up coming soon</span>
          {/* <Link to='/signup' className='signuplink'>Click here to Sign Up</Link> */}
          {/* <label className='checkbox'>
                    <input type="checkbox" checked="checked" name="remember"/> Remember me 
                </label> */}
        </div>

        {/* <div className="container containerbtn">
                <button type="button" className="cancelbtn">Cancel</button>
                <span className="psw">Forgot <a href="#">password?</a></span>
            </div> */}
      </form>

      <ToastContainer autoClose={3000} />
    </div>
  );
};

export default Loginpage;

export function isEmpty(obj = {}) {
  return Object.keys(obj).length === 0;
}

export function isString(value) {
  return typeof value === "string" || value instanceof String;
}

export function isNumber(value) {
  return typeof value == "number" && !isNaN(value);
}

export function isBoolean(value) {
  return value === true || value === false;
}

export function isNil(value) {
  return typeof value === "undefined" || value === null;
}

export function convertType(value) {
  if (isNumber(value)) {
    return value.toString();
  }

  if (isBoolean(value)) {
    return value ? "1" : "-1";
  }

  return value;
}

export function filterRows(rows, filters) {
  if (!filters) return rows;

  return rows.filter((row) => {
    return Object.keys(filters).every((accessor) => {
      const value = row[accessor];
      const searchValue = filters[accessor];

      if (isString(value)) {
        if (searchValue === "Not Custom") {
          return !value.toLowerCase().includes("custom");
        }
        return value.toLowerCase().includes(searchValue.toLowerCase());
      }

      if (isBoolean(value)) {
        return (
          (searchValue === "true" && value) ||
          (searchValue === "false" && !value)
        );
      }

      if (isNumber(value)) {
        return value === searchValue;
      }

      return false;
    });
  });
}

export function sortRows(rows, sort) {
  if (sort.order === "") return rows;
  return rows.sort((a, b) => {
    const { order, orderBy } = sort;

    if (isNil(a[orderBy])) return 1;
    if (isNil(b[orderBy])) return -1;

    const aLocale = convertType(a[orderBy]);
    const bLocale = convertType(b[orderBy]);

    if (order === "asc") {
      return aLocale.localeCompare(bLocale, "en", {
        numeric: isNumber(b[orderBy]),
      });
    } else {
      return bLocale.localeCompare(aLocale, "en", {
        numeric: isNumber(a[orderBy]),
      });
    }
  });
}

export function paginateRows(sortedRows, activePage, rowsPerPage) {
  return [...sortedRows].slice(
    (activePage - 1) * rowsPerPage,
    activePage * rowsPerPage
  );
}

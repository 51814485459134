import "./CartPage.css";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Components
import CartItem from "../components/CartItem";

//Actions
import { addToCart, removeFromCart } from "../redux/actions/cartActions";
import Paybutton from "../components/Paybutton";

const CartPage = () => {
  const dispatch = useDispatch();
  const [candlesInCart, setCandlesInCart] = useState([]);
  const [changeCart, setChangeCart] = useState({ loading: false, id: "" });
  const [enterPromo, setEnterPromo] = useState(false);
  const [promoCheck, setPromoCheck] = useState({});
  const [promoAmount, setPromoAmount] = useState(0);

  // Grab candle ids in cart from state or localStorage
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  // Using candle ids fetch candle data to populate info
  useEffect(async () => {
    const { data } = await axios.post("/api/candles/cart", { cartItems });
    setCandlesInCart(data);
    setChangeCart({ loading: false, id: "" });
  }, [cartItems]);

  // handle change in qty
  const qtyChangeHandler = (id, qty, scent) => {
    setChangeCart({ loading: true, id: id });
    dispatch(addToCart(id, qty, scent));
  };

  // handle removing from cart
  const removeHandler = (id) => {
    dispatch(removeFromCart(id));
  };

  // cart total qty
  const getCartCount = () => {
    return candlesInCart.reduce((qty, item) => Number(item.qty) + qty, 0);
  };

  // cart total price
  const getCartSubTotal = () => {
    return candlesInCart.reduce(
      (price, item) => item.price * item.qty + price,
      0
    );
  };

  // console.log(candlesInCart);
  return (
    <div className="cartpage">
      <div className="cartpage_left">
        <h2 className="cartpage_title">Shopping Cart</h2>
        {candlesInCart.length === 0 ? (
          <div>
            Your cart is empty <Link to="/">Go Back</Link>
          </div>
        ) : (
          candlesInCart.map((item) => (
            <CartItem
              key={item._id}
              item={item}
              qtyChangeHandler={qtyChangeHandler}
              removeHandler={removeHandler}
              changeCart={changeCart}
            />
          ))
        )}
      </div>
      <div className="cartpage_right">
        <div className="cartpage_info">
          <div className="flex justify-between">
            <h4 className="text-m font-semibold">
              Subtotal ({getCartCount()}) items:
            </h4>
            <p className="float-right p-0 font-semibold">
              ${getCartSubTotal().toFixed(2)}{" "}
            </p>
          </div>
          <div className="flex justify-between">
            <h4 className="text-m font-semibold">Shipping:</h4>
            {getCartSubTotal().toFixed(2) >= 40 ? (
              <p className="p-0 font-semibold">
                <s className="text-sm pr-3 font-normal">$9.45</s>
                $0.00
              </p>
            ) : (
              <p className="p-0">$9.45</p>
            )}
          </div>
          <p className="text-sm p-1">*Free shipping on orders above $40*</p>
          {promoAmount !== 0 ? (
            <>
              <div>
                <h4 className="text-m font-semibold">
                  Discount {promoAmount}%{" "}
                </h4>
              </div>
              <div className="flex justify-between">
                <h3 className="text-lg font-bold">Total:</h3>
                <p className="font-bold">
                  <s className="text-sm pr-3 font-normal">
                    $
                    {getCartSubTotal().toFixed(2) >= 40
                      ? getCartSubTotal().toFixed(2)
                      : getCartSubTotal().toFixed(2) > 0
                      ? Number(getCartSubTotal().toFixed(2)) + 9.45
                      : 0.0}
                  </s>
                  $
                  {(
                    ((getCartSubTotal() >= 40
                      ? getCartSubTotal()
                      : getCartSubTotal() > 0
                      ? getCartSubTotal() + 9.45
                      : 0.0) *
                      (100 - promoAmount)) /
                    100
                  ).toFixed(2)}
                </p>
              </div>
            </>
          ) : (
            <div className="flex justify-between">
              <h3 className="text-lg font-bold">Total:</h3>
              <p className="font-bold">
                $
                {getCartSubTotal().toFixed(2) >= 40
                  ? getCartSubTotal().toFixed(2)
                  : getCartSubTotal().toFixed(2) > 0
                  ? Number(getCartSubTotal().toFixed(2)) + 9.45
                  : 0.0}
              </p>
            </div>
          )}
          <div>
            <h4
              className="cursor-pointer hover:"
              onClick={() => setEnterPromo(!enterPromo)}
            >
              Have a promo code? Apply code at checkout
            </h4>
          </div>
        </div>

        <div>
          <Paybutton cartItems={candlesInCart} />
        </div>
      </div>

      <ToastContainer autoClose={3000} />
    </div>
  );
};

export default CartPage;

import "./HomePage.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
// import { Link } from 'react-router-dom'
// import { toast } from 'react-toastify'

const HomePage = () => {
  // if(sessionStorage.getItem("showmsg")){
  //   toast.success('hello', {duration:3000})
  //   sessionStorage.removeItem("showmsg");
  //   }
  useEffect(() => {
    AOS.init();
  }, []);

  console.log("home");

  return (
    <div className="homepage">
      <div className="background">
        <div className="wrapper">
          <div className="homepage_head">
            <h1>Lyndsey's Scents</h1>
            <h2>Handmade Can-dles</h2>
            <p>
              Lyndsey's Scents will recycle any can! From your daily beer, wine,
              soda, and cold brew cans, <br />
              we can turn anything into a creative candle.
            </p>
          </div>

          <div className="buttons">
            <a href="/faq">
              <button className="homebtn">Learn More</button>
            </a>
            <a href="/contact">
              <button className="homebtn">Request a Custom Candle</button>
            </a>
          </div>
        </div>
      </div>

      <div className="wrapper2">
        <div className="container">
          <div className="row">
            <section className="homedecor-section">
              <div className="image-wrapper first" data-aos="fade-right">
                <img src={require("./images/img-homedecor.jpg")} alt="" />
              </div>
              <header>
                <h2 className="text-lg font-semibold">
                  Home Decor
                  <br />
                </h2>
              </header>
              <p className="section-text">
                Handmade, all-natural soy candles in beer, soda, or custom cans.
                Using recycled cans, these unique candles are perfect for your
                next home decor or gift idea. Find out more.
              </p>
              <a href="/faq">
                <button className="homebtn faqbtn">FAQ</button>
              </a>
            </section>
            <section className="shop-section">
              <div className="image-wrapper shop-img" data-aos="fade-left">
                <a href="/shop" className="image featured">
                  <img src={require("./images/img-shop.jpg")} alt="" />
                </a>
              </div>
              <header>
                <h2 className="text-lg font-semibold">Store</h2>
              </header>
              <p className="section-text">
                With a selection of over 75 cans, we are always looking for new
                and unique cans to add to our inventory. Visit our online store
                or visit us in person to see some of our awesome collection!
              </p>
              <a href="/shop">
                <button className="homebtn shopbtn">Shop</button>
              </a>
            </section>
          </div>
        </div>
      </div>

      <div className="aboutus-container">
        <section className="aboutus-img">
          <div className="image-wrapper2 first" data-aos="flip-down">
            <img src={require("./images/img-aboutus.jpg")} alt="" />
          </div>
        </section>
        <section className="aboutus-text">
          <h2 className="text-lg font-semibold">About Us</h2>
          <p>Local, hand-made, unique candles. Crafted with care. </p>
          <p>
            Started in Montgomery County, PA right outside of Philadelphia, we
            have been longing to start a side-hustle and Covid turned out to be
            the proper motivator. After drinking lots of booze (but not too
            much!) during peak pandemic, we realized that all of these cans
            could be re-used for a fun purpose. We thought to ourselves…. Who
            doesn’t love candles? And who doesn’t love booze?! These turned out
            to be the questions that sparked this small-business creation.
          </p>
          <p>
            All candles are made from soy wax, essential oils for endless
            sniffs, and recycled cans (no booze is wasted in the process).
          </p>
          <p>
            We would love to help serve you with your candle creation! If there
            is anything you cannot find, please feel free to reach out with any
            questions and inquiries!
          </p>
          <p>Enjoy the smells.</p>
        </section>
      </div>
    </div>
  );
};

export default HomePage;

import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { resetCart } from "../redux/actions/cartActions";

const Successpage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetCart());
  }, [dispatch]);

  return (
    <div>
      <h1 className="text-center text-4xl font-semibold pt-5">
        Thank you for your purchase!
      </h1>
      <h2 className="text-center text-xl pt-2">
        Your order has been received.
      </h2>
      <h2 className="text-center text-xl">
        Please check your email for order confirmation.
      </h2>
      <div className="flex justify-center">
        <a href="/shop">
          <button className="text-center text-sm mr-4 homebtn">
            Keep Shopping
          </button>
        </a>
        <a href="/contact">
          <button className="text-center text-sm mr-4 homebtn">
            Contact Us
          </button>
        </a>
      </div>
      <img
        className="mx-auto pt-4 mb-10"
        src={require("./images/luna.jpg")}
        alt=""
        style={{ width: "350px" }}
      ></img>
    </div>
  );
};

export default Successpage;

import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import "./Sidedrawer.css";

const Sidedrawer = ({ show, click, setSideToggle }) => {
  const sideDrawerClass = ["sidedrawer"];
  if (show) {
    sideDrawerClass.push("show");
  }

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const User = useSelector((state) => state.user);
  const { user } = User;

  const getCartCount = () => {
    return cartItems.reduce((qty, item) => Number(item.qty) + qty, 0);
  };

  return (
    <div
      className={sideDrawerClass.join(" ")}
      onClick={() => setSideToggle(false)}
    >
      <ul className="sidedrawer_links" onClick={click}>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/cart">
            <i className="fas fa-shopping-cart"></i>
            <span>
              Cart{" "}
              <span className="sidedrawer_cartbadge">{getCartCount()}</span>
            </span>
          </Link>
        </li>
        <li>
          <Link to="/shop"> Shop</Link>
        </li>
        <li>
          <Link to="/faq"> FAQ</Link>
        </li>
        <li>
          <Link to="/contact">Contact</Link>
        </li>
        {user === "62a5400420423a2c13e8180b" ? (
          <li>
            <Link to="/admindashboard" className={`admin_link links`}>
              <span>Admin</span>
            </Link>
          </li>
        ) : (
          <></>
        )}
        {user !== null && user !== undefined ? (
          <></>
        ) : (
          <li>
            <Link to="/login" className={`login_link links`}>
              <span>Login</span>
            </Link>
          </li>
        )}
      </ul>
    </div>
  );
};

export default Sidedrawer;

import React from "react";
import "../pages/Adminpage.css";

const Pagination = ({
  activePage,
  count,
  rowsPerPage,
  totalPages,
  setActivePage,
}) => {
  const pageNumbers = [...Array(totalPages + 1).keys()].slice(1);

  const prevPage = () => {
    if (activePage !== 1) setActivePage(activePage - 1);
  };
  const nextPage = () => {
    if (activePage !== totalPages) setActivePage(activePage + 1);
  };

  const beginning = activePage === 1 ? 1 : rowsPerPage * (activePage - 1) + 1;
  const end = activePage === totalPages ? count : beginning + rowsPerPage - 1;

  return (
    <div className="pagination">
      <nav
        className="page-nav"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <ul style={{ display: "flex" }}>
          <li>
            <button onClick={prevPage}>Previous</button>
          </li>
          {pageNumbers.map((num) => (
            <li
              key={num}
              className={`page-item ${activePage === num ? "active" : ""}`}
            >
              <button onClick={() => setActivePage(num)}>{num}</button>
            </li>
          ))}
          <li>
            <button onClick={nextPage}>Next</button>
          </li>
        </ul>
      </nav>
      <p>
        Page {activePage} of {totalPages}
      </p>
      <p>
        Rows: {beginning === end ? end : `${beginning}-${end}`} of {count}
      </p>
    </div>
  );
};

export default Pagination;

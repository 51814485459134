import * as actionTypes from '../constants/candleConstants'

export const getCandlesReducer = (state = {candles:[]}, action) =>{
    switch(action.type){
        case actionTypes.GET_PRODUCTS_REQUEST:
            return {
                loading: true,
                candles: []
            }
        case actionTypes.GET_PRODUCTS_SUCCESS:
            return{
                loading: false,
                candles: action.payload//.candles,
                // pagination: action.payload.pagination
            }

        case actionTypes.GET_PRODUCTS_FAIL:
            return{
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}

export const getCandleDetailsReducer = (state = {candle:{}}, action)=>{
    switch(action.type) {
        case actionTypes.GET_PRODUCT_DETAILS_REQUEST:
            return {
                loading: true
            };

        case actionTypes.GET_PRODUCT_DETAILS_SUCCESS:
            return{
                loading: false,
                candle: action.payload
            };

        case actionTypes.GET_PRODUCT_DETAILS_FAIL:
            return{
                loading: false,
                error: action.payload
            };

        case actionTypes.GET_PRODUCT_DETAILS_RESET:
            return{
                candle: {}
            };

        default:
            return state;
    }
}

export const postCandleReducer = (state = {candle:{}}, action) => {
    switch(action.type){
        case actionTypes.POST_PRODUCT_REQUEST:
            return {
                loading: true
            }

        case actionTypes.POST_PRODUCT_SUCCESS:
            return {
                loading: false,
                candle: action.payload
            }

        case actionTypes.POST_PRODUCT_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        
        default:
            return state; 
    }
    
}

export const putCandleReducer = (state = {candle:{}}, action) => {
    switch(action.type){
        case actionTypes.PUT_PRODUCT_REQUEST:
            return {
                loading: true
            }

        case actionTypes.PUT_PRODUCT_SUCCESS:
            return {
                loading: false,
                candle: action.payload
            }

        case actionTypes.PUT_PRODUCT_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        
        default:
            return state; 
    }
    
}

export const deleteCandleReducer = (state = {candle:{}}, action) => {
    switch(action.type){
        case actionTypes.DELETE_PRODUCT_REQUEST:
            return {
                loading: true
            }

        case actionTypes.DELETE_PRODUCT_SUCCESS:
            return {
                loading: false,
                candle: action.payload
            }

        case actionTypes.DELETE_PRODUCT_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        
        default:
            return state; 
    }
    
}